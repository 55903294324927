/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import './_variables.scss';
@import './_fonts.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

html{

  text-align: left;
  font-size: 15px;

  body{
    margin: 0px;
    overflow-x: hidden;
    font-family: 'BoschSansRegular';
  }
}

.container {
  h1, h2, h3, h4, h5, h6 {
      font-family: 'BoschSansRegular', sans-serif;
      color: $default_grey;
  }

  p {
      font-family: 'BoschSansLight', sans-serif;
      font-size: 15px;
      color: $default_grey;
  }
}

b {
  font-weight: bold;
}

.btn {
  font: 15px/26px BoschSansRegular;
  border-radius: 0;
}

.table-responsive{
  .paginator{
    .previous:hover, .next:hover{
      cursor: pointer;
      background-color: grey;
    }
  }
}

.top-footer {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #F9FBFC 0%, #E3EAF3 96%);
  height: 5px;
}

.footer-elm {
  padding: 20px;

  a {
    color: #ACACAC !important;
  }
}

.footer-elm ul {
  font: 13px BoschSansLight;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer-elm span {
  font: 13px BoschSansBlack;
}

.copyright {
  position: absolute;
  bottom: 0;
  z-index: 1030;
  right: 10px;
  color: #ACACAC !important;
  font: 13px BoschSansLight;
  text-decoration: none;

  @media (min-width: 768px) {
    display: normal !important;
  }

  @media (max-width: 767px) {
    display: none !important;
  }
}

.copyrightXS {
  bottom: 0;
  z-index: 1030;
  right: 10px;
  color: #ACACAC !important;
  font: 13px BoschSansLight;
  text-decoration: none;

  @media (min-width: 768px) {
    display: none !important;
  }

  @media (max-width: 767px) {
    display: normal !important;
  }
}

footer {
  font-style: normal !important;
  font-family: 'BoschSansBlack', sans-serif;
  font-size: 13px;
  background-color: white;
  color: #838383;
}
