
@font-face {
  font-family: 'BoschSansBlack';
  src: url("/assets/fonts/BoschSans-Black.ttf") format("ttf"), url("/assets/fonts/BoschSans-Black.woff") format("woff"), url("/assets/fonts/BoschSans-Black.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BoschSansBlackItalic';
  src: url("/assets/fonts/BoschSans-BlackItalic.ttf") format("ttf"), url("/assets/fonts/BoschSans-BlackItalic.woff") format("woff"), url("/assets/fonts/BoschSans-BlackItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'BoschSansBold';
  src: url("/assets/fonts/BoschSans-Bold.ttf") format("ttf"), url("/assets/fonts/BoschSans-Bold.woff") format("woff"), url("/assets/fonts/BoschSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BoschSansBoldItalic';
  src: url("/assets/fonts/BoschSans-BoldItalic.ttf") format("ttf"), url("/assets/fonts/BoschSans-BoldItalic.woff") format("woff"), url("/assets/fonts/BoschSans-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'BoschSansLight';
  src: url("/assets/fonts/BoschSans-Light.ttf") format("ttf"), url("/assets/fonts/BoschSans-Light.woff") format("woff"), url("/assets/fonts/BoschSans-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BoschSansLightItalic';
  src: url("/assets/fonts/BoschSans-LightItalic.ttf") format("ttf"), url("/assets/fonts/BoschSans-LightItalic.woff") format("woff"), url("/assets/fonts/BoschSans-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'BoschSansMedium';
  src: url("/assets/fonts/BoschSans-Medium.ttf") format("ttf"), url("/assets/fonts/BoschSans-Medium.woff") format("woff"), url("/assets/fonts/BoschSans-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BoschSansMediumItalic';
  src: url("/assets/fonts/BoschSans-MediumItalic.ttf") format("ttf"), url("/assets/fonts/BoschSans-MediumItalic.woff") format("woff"), url("/assets/fonts/BoschSans-MediumItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'BoschSansRegular';
  src: url("/assets/fonts/BoschSans-Regular.ttf") format("ttf"), url("/assets/fonts/BoschSans-Regular.woff") format("woff"), url("/assets/fonts/BoschSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BoschSansRegularItalic';
  src: url("/assets/fonts/BoschSans-RegularItalic.ttf") format("ttf"), url("/assets/fonts/BoschSans-RegularItalic.woff") format("woff"), url("/assets/fonts/BoschSans-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
